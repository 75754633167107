import { Button, Spinner, Text } from "@chakra-ui/react";
import ContainerAuth from "components/ContainerAuth";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { confirmEmail } from "service/EmailService";
import { useQuery } from "utils/useQuery";
import * as ROUTES from "../constants/Routes";
import { useTranslation } from "react-i18next";

const ConfirmEmail = () => {
  const query = useQuery();
  const history = useHistory();
  const [showMessageSucess, setShowMessageSucess] = useState(false);
  const [showMessageError, setShowMessageError] = useState(false);
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleConfirmEmail = async () => {
    setLoading(true);
    try {
      await confirmEmail(query.get("confirm"), query.get("id"));
      setShowMessageSucess(true);
    } catch (error) {
      setShowMessageError(true);
      setTitleError(t(`${error.errors[0].code.toUpperCase()}_TITLE`));
      setDescriptionError(
        t(`${error.errors[0].code.toUpperCase()}_DESCRIPTION`)
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    handleConfirmEmail();
  }, []);

  if (loading) {
    return (
      <ContainerAuth>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </ContainerAuth>
    );
  }

  return (
    <ContainerAuth>
      {showMessageSucess && (
        <>
          <Text
            fontSize={20}
            fontWeight="medium"
            width={320}
            textAlign="center"
          >
            {t("CONFIRM_EMAIL.EMAIL_VALIDATION_TITLE")}
          </Text>
          <Text width={350} textAlign="center">
            {t("CONFIRM_EMAIL.EMAIL_VALIDATION_DESCRIPTION")}
          </Text>
          <Button
            colorScheme="red"
            mt={8}
            onClick={() => history.push(ROUTES.LOGIN)}
          >
            {t("MAKE_LOGIN")}
          </Button>
        </>
      )}
      {showMessageError && (
        <>
          <Text
            fontSize={20}
            fontWeight="medium"
            width={320}
            textAlign="center"
          >
            {titleError}
          </Text>
          <Text width={350} textAlign="center">
            {descriptionError}
          </Text>
        </>
      )}
    </ContainerAuth>
  );
};

export default ConfirmEmail;
