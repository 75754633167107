import { get, post } from "utils/fetch";

export const postGeneralComments = async (
  comment,
  responseID,
  questionID,
  callback
) => {
  post("/api/courses/correction/comments/", {
    response_id: responseID,
    question_id: questionID,
    comment: comment,
  })
    .then((data) => {
      callback(data.comment, data.all_comments);
    })
    .catch((err) => console.log("Error: ", err));
};

export const getGeneralComments = async (responseID, questionID, callback) => {
  get(
    `/api/courses/correction/comments/?response_id=${responseID}&question_id=${questionID}`
  )
    .then((data) => {
      callback(data.comment, data.all_comments);
    })
    .catch((err) => console.log("Error: ", err));
};

export const postMultipleChoice = async (
  questionId,
  responseId,
  choicesFeedback,
  correctAnswers,
  correctAnswersIndex
) => {
  post("api/courses/correction/multiplechoice/", {
    question_id: questionId,
    response_id: responseId,
    choices_feedback: choicesFeedback,
    correct_answer: correctAnswers,
    correct_answer_index: correctAnswersIndex,
  }).catch((err) => {
    console.log(err);
  });
};

export const sendToMoodle = async (questionId, responseId) => {
  await post("api/courses/correction/send_moodle", {
    question_id: questionId,
    response_id: responseId,
  });
};
