import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Hotjar from "@hotjar/browser";

import { getUserLogin } from "../src/utils/user";

import * as ROUTES from "../src/constants/Routes";

import Login from "./views/Login";
import Register from "./views/Register";
import NavBar from "./components/NavBar";
import ForgotPassword from "views/ForgotPassword";
import ResetPassword from "views/ResetPassword";
import { Home } from "views/NewHome.jsx";
import SettingsView from "views/Settings";
import PrivacyView from "views/PrivacyView";
import SendFeedbackScreen from "../src/views/SendFeedbackScreen";
import ReviewPage from "../src/views/ReviewPage";
import QuestionScreen from "../src/views/QuestionScreen";
import MyEvaluationView from "../src/views/MyEvaluationView";
import EditFeedback from "../src/views/EditFeedback";
import EditEmail from "views/EditEmail";
import ConfirmEmail from "views/ConfirmEmail";

import { WorkStatusProvider } from "hooks/useWorkStatus";
import { ParamsMoodleProvider } from "hooks/useParamsMoodle";
import { NavigationCorrectionProvider } from "hooks/useNavigationCorrection";
import { BlindCorrectionProvider } from "hooks/useBlindCorrection";

function App() {
  useEffect(() => {
    if (process.env.REACT_APP_HOTJAR_KEY) {
      Hotjar.init(process.env.REACT_APP_HOTJAR_KEY, 6);
    }
  }, []);

  return (
    <div className="App">
      <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_KEY}>
        <Router>
          <Switch>
            {getUserLogin() && (
              <>
                <ParamsMoodleProvider>
                  <WorkStatusProvider>
                    <NavigationCorrectionProvider>
                      <BlindCorrectionProvider>
                        <NavBar />
                        <Route exact path={ROUTES.HOME}>
                          <ReviewPage />
                        </Route>
                        <Route exact path={ROUTES.SETTINGS}>
                          <SettingsView />
                        </Route>
                        <Route path={ROUTES.QUESTION}>
                          <QuestionScreen />
                        </Route>
                        <Route exact path={ROUTES.EVALUATE}>
                          <MyEvaluationView />
                        </Route>
                        <Route path={ROUTES.SEND_FEEDBACK}>
                          <SendFeedbackScreen />
                        </Route>
                        <Route path={ROUTES.EDIT_EMAIL}>
                          <EditEmail />
                        </Route>
                        <Route path={ROUTES.REVIEW_FEEDBACK}>
                          <EditFeedback />
                        </Route>
                        <Route path={ROUTES.PRIVACY_POLICY}>
                          <PrivacyView isLogged={true} />
                        </Route>
                      </BlindCorrectionProvider>
                    </NavigationCorrectionProvider>
                  </WorkStatusProvider>
                </ParamsMoodleProvider>
              </>
            )}
            <Route exact path={ROUTES.LOGIN}>
              <Login />
            </Route>
            <Route path={ROUTES.REGISTER}>
              <Register />
            </Route>
            <Route exact path={ROUTES.FORGOT_PASSWORD}>
              <ForgotPassword />
            </Route>
            <Route exact path={ROUTES.PASSWORD_RESET}>
              <ResetPassword />
            </Route>
            <Route exact path={ROUTES.HOME}>
              <Home />
            </Route>
            <Route exact path={ROUTES.PRIVACY_POLICY}>
              <PrivacyView isLogged={false} />
            </Route>
            <Route path={ROUTES.CONFIRM_EMAIL}>
              <ConfirmEmail />
            </Route>
          </Switch>
        </Router>
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
