import { useEffect, useState } from "react";
import { resendConfirmEmail } from "service/EmailService";

const { Text, Button, useToast } = require("@chakra-ui/react");
const { default: ContainerAuth } = require("./ContainerAuth");
const { useTranslation } = require("react-i18next");

export default function ActiveAccount({ email, uid }) {
  const { t } = useTranslation();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [seconds, setSeconds] = useState(60);

  const handleClick = () => {
    setIsDisabled(true);
    setSeconds(60);
  };

  useEffect(() => {
    let interval = null;

    if (isDisabled && seconds > 0) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      setIsDisabled(false);
    }

    return () => clearInterval(interval);
  }, [isDisabled, seconds]);
  const handleResendEmail = async () => {
    setIsLoading(true);
    try {
      await resendConfirmEmail(email, uid);
      setIsLoading(false);
      handleClick();
    } catch (error) {
      toast({
        title: t("CONFIRM_EMAIL.EMAIL_VALIDATION_ERROR_TITLE"),
        status: "error",
        duration: 3000,
        position: "top",
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  return (
    <ContainerAuth>
      <Text fontSize={20} fontWeight="medium" width={320} textAlign="center">
        {t("CONFIRM_EMAIL.TO_ACTIVE_ACCOUNT_TITLE")}
      </Text>
      <Text width={350} textAlign="center">
        {t("CONFIRM_EMAIL.TO_ACTIVE_ACCOUNT_DESCRIPTION")}
      </Text>
      <Button
        colorScheme="red"
        isLoading={isLoading}
        mt={4}
        onClick={() => handleResendEmail()}
        isDisabled={isDisabled}
      >
        {isDisabled ? `${t("WAIT")} ${seconds}s` : t("RESEND_EMAIL")}
      </Button>
    </ContainerAuth>
  );
}
