import {
  Button,
  Flex,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogContent,
  AlertDialogBody,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useNavigationCorrection } from "hooks/useNavigationCorrection";
import { useContext, useCallback } from "react";
import { EvaluationContext, EvaluationActions } from "./MyEvaluationContext";
import QuestionTypes from "constants/QuestionTypes";
import { post } from "utils/fetch";
import { sendToMoodle } from "service/MyEvaluationServices";

const ConfirmCorrectionModal = ({ isOpen, onClose, nextPageCallback }) => {
  const { t } = useTranslation();
  return (
    <AlertDialog
      motionPreset="slideInBottom"
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>
          {t("CORRECTION_NOT_COMPLETED_DIALOG.TITLE")}
        </AlertDialogHeader>
        <AlertDialogBody>
          {t("CORRECTION_NOT_COMPLETED_DIALOG.DESCRIPTION")}
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button
            onClick={() => {
              onClose();
            }}
            mr={2}
          >
            {t("CORRECTION_NOT_COMPLETED_DIALOG.CANCEL_BUTTON")}
          </Button>
          <Button
            colorScheme="blue"
            onClick={() => {
              onClose();
              nextPageCallback();
            }}
          >
            {t("CORRECTION_NOT_COMPLETED_DIALOG.OK_BUTTON")}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

const MyEvaluationNavigationButtons = () => {
  const {
    handleNextPage,
    handlePreviousPage,
    questionType,
    questionId,
    responseId,
  } = useNavigationCorrection();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { tags, maxScore, score, dispatch } = useContext(EvaluationContext);

  const { t } = useTranslation();

  const handleSaveScore = useCallback(
    (value) => {
      dispatch({
        type: EvaluationActions.updateEvaluation,
        payload: { score: Number(value) },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [score, dispatch]
  );

  const handleSaveEndTime = () => {
    post(
      "api/courses/correction/",
      {
        question_id: questionId,
        answer_id: responseId,
        finalized_at: new Date(),
      },
      "PUT"
    );
  };

  const handleSendToMoodle = async () => {
    try {
      await sendToMoodle(questionId, responseId);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickNext = () => {
    const tags_correction = tags.filter((tag) => !tag.isRecommendation);
    if (
      questionType === QuestionTypes.PARAGRAPH_TEXT &&
      !maxScore &&
      tags_correction.length
    ) {
      handleSaveScore(-1);
      handleSaveEndTime();
      handleNextPage();
    } else if (questionType === QuestionTypes.MULTIPLE_CHOICE && !maxScore) {
      handleSaveScore(-1);
      handleSaveEndTime();
      handleNextPage();
    } else if (
      (questionType === QuestionTypes.PARAGRAPH_TEXT &&
        !maxScore &&
        !tags_correction.length) ||
      (maxScore && score === null)
    ) {
      onOpen();
    } else {
      handleSaveEndTime();
      handleNextPage();
    }
    handleSendToMoodle();
  };
  return (
    <>
      <ConfirmCorrectionModal
        isOpen={isOpen}
        onClose={onClose}
        nextPageCallback={handleNextPage}
      />
      <Flex justifyContent="space-between" width="100%" marginTop="2">
        <Button width="24" onClick={handlePreviousPage}>
          {t("PREVIOUS")}
        </Button>
        <Button
          colorScheme="blue"
          width="24"
          fontWeight="normal"
          onClick={handleClickNext}
        >
          {t("NEXT")}
        </Button>
      </Flex>
    </>
  );
};

export default MyEvaluationNavigationButtons;
