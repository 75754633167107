import React from "react";

import { Avatar, Spinner, Tag, TagLabel } from "@chakra-ui/react";
import { useBlindCorrection } from "hooks/useBlindCorrection";

const StudentTag = ({
  avatar = "",
  name = "name",
  description = "description",
  mousePointerStyle = "",
  className = "",
  isLoading = false,
  didShowBackground = true,
  isQuestion = false,
}) => {
  const { isBlind } = useBlindCorrection();
  return (
    <>
      <Tag
        w="max-content"
        maxW={["290px", "600px", "800px"]}
        px={2}
        py={1.5}
        colorScheme="white"
        height={8}
        backgroundColor={didShowBackground ? "gray.50" : "transparent"}
        borderRadius="full"
        className={className}
        style={{ cursor: mousePointerStyle }}
      >
        <Avatar
          src={!isQuestion && isBlind ? null : avatar}
          size="sm"
          name={!isQuestion && isBlind ? null : name}
          ml={-1}
          mr={2}
          boxSize="25px"
        />
        <TagLabel fontSize="md">
          {!isQuestion && isBlind ? "***** *****" : description}
        </TagLabel>
        {isLoading && <Spinner color={"blue.700"} ml={"2"} />}
      </Tag>
    </>
  );
};

export default StudentTag;
