import React from "react";
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { BrazilIcon } from "media/BrazilIcon";
import { USAIcon } from "media/USAIcon";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { postLang } from "service/SettingsViewServices";
import { storageGet } from "utils/user";

const ChangeLang = () => {
  const { i18n, t } = useTranslation();

  const handleClick = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("user-lang", lang);
    if (storageGet("tokenAuth")) {
      postLang(lang);
    }
  };

  return (
    <Menu>
      <MenuButton
        backgroundColor="#F7F7FE"
        mx={2}
        as={Button}
        rightIcon={<ChevronDownIcon />}
        p={2}
      >
        {localStorage.getItem("user-lang") === "en-US" ? (
          <USAIcon />
        ) : (
          <BrazilIcon />
        )}
      </MenuButton>
      <MenuList>
        <MenuItem onClick={() => handleClick("pt-BR")}>
          <BrazilIcon /> <Text ml={2}>{t("PORTUGUESE")}</Text>
        </MenuItem>
        <MenuItem onClick={() => handleClick("en-US")}>
          <USAIcon /> <Text ml={2}>{t("ENGLISH")}</Text>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default ChangeLang;
