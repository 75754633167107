import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Grid,
  Select,
} from "@chakra-ui/react";
import { EvaluationContext } from "./MyEvaluationContext";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import RichEditorComponent from "components/Editor/RichEditorComponent";
import { EditorState } from "draft-js";
import useDebounce from "utils/useDebounce";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";

const MyEvaluationChoice = ({ choice, index }) => {
  const { t } = useTranslation();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const {
    correctAnswersIndex,
    updateCorrectAnswers,
    setChoicesFeedback,
    choicesFeedback,
  } = useContext(EvaluationContext);
  const debouncedEditorState = useDebounce(editorState, 500);
  const [selected, setSelected] = useState(0);

  const onClickChoice = useCallback((index) => {
    setSelected(index);
  }, []);

  useEffect(() => {
    setChoicesFeedback((feedbacks) =>
      feedbacks.map((f, i) => {
        if (i === selected) {
          if (debouncedEditorState.getCurrentContent().getPlainText().trim()) {
            return stateToHTML(debouncedEditorState.getCurrentContent());
          }
          return "";
        }
        return f;
      })
    );
  }, [debouncedEditorState]);

  useEffect(() => {
    const html = choicesFeedback[selected] ?? "";
    const state = EditorState.createWithContent(stateFromHTML(html));
    setEditorState(state);
  }, [selected]);

  const selectEnum = {
    0: t("INCORRECT"),
    1: t("CORRECT"),
  };

  const isCorrectAnswer = useMemo(
    () => correctAnswersIndex.includes(index),
    [correctAnswersIndex, index]
  );

  const [value, setValue] = useState(isCorrectAnswer ? 1 : 0);

  const onClickAnswer = useCallback(() => {
    if (isCorrectAnswer) {
      updateCorrectAnswers(correctAnswersIndex.filter((i) => i !== index));
      return;
    }
    updateCorrectAnswers([...correctAnswersIndex, index]);
  }, [correctAnswersIndex, index, isCorrectAnswer, updateCorrectAnswers]);

  const onChangeSelectOptionHandler = (event) => {
    setValue(Number(event.target.value));
    onClickAnswer();
  };

  const OptionList = () =>
    Object.keys(selectEnum).map((index) => (
      <option value={index} key={uuidv4()}>
        {selectEnum[index]}
      </option>
    ));

  return (
    <Grid templateColumns="4fr 1fr" gap={6}>
      <AccordionItem onClick={() => onClickChoice(index)}>
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              {`${String.fromCharCode(0x61 + index)}) ${choice}`}
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <RichEditorComponent
            editorState={editorState}
            setEditorState={setEditorState}
          />
        </AccordionPanel>
      </AccordionItem>
      <Select mb={2} value={value} onChange={onChangeSelectOptionHandler}>
        <OptionList />
      </Select>
    </Grid>
  );
};

export default MyEvaluationChoice;
