import { Box } from "@chakra-ui/react";
import { Editor, RichUtils } from "draft-js";
import React from "react";
import InlineStyleControls from "./InlineStylesControls";

export default function RichEditorComponent({
  editorState,
  setEditorState,
  onChangeCallback,
}) {
  const editor = React.useRef(null);

  function focusEditor() {
    editor.current.focus();
  }

  React.useEffect(() => {
    focusEditor();
  }, []);

  const toggleInlineStyle = (inlineStyle) => {
    const newState = RichUtils.toggleInlineStyle(editorState, inlineStyle);
    setEditorState(newState);
  };

  return (
    <Box
      maxW="100%"
      h="150px"
      border="1px"
      borderColor="gray.100"
      borderRadius="lg"
      onClick={focusEditor}
    >
      <InlineStyleControls
        currentInlineStyle={editorState.getCurrentInlineStyle()}
        onToggle={toggleInlineStyle}
      />
      <Box p="2" h={"100px"} overflowX={"hidden"} overflowY={"auto"}>
        <Editor
          ref={editor}
          editorState={editorState}
          onChange={(editorState) => {
            setEditorState(editorState);
            if (onChangeCallback) {
              onChangeCallback(editorState);
            }
          }}
        />
      </Box>
    </Box>
  );
}
