import { Accordion, Flex } from "@chakra-ui/react";
import { useContext } from "react";
import { EvaluationContext } from "./MyEvaluationContext";
import MyEvaluationChoice from "./MyEvaluationChoice";

const MyEvaluationChoices = () => {
  const { evaluation } = useContext(EvaluationContext);

  return (
    <Flex width="100%" flexDirection="column" marginY="4">
      <Accordion defaultIndex={0} allowToggle>
        {evaluation.possible_choices.map((choice, index) => (
          <MyEvaluationChoice choice={choice} index={index} key={choice} />
        ))}
      </Accordion>
    </Flex>
  );
};

export default MyEvaluationChoices;
