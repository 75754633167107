import { Box } from "@chakra-ui/react";
import MyEvaluationChoices from "./MyEvaluationChoices";
import MyEvaluationQuestion from "./MyEvaluationQuestion";
import MyEvaluationNavigationButtons from "./MyEvaluationNavigationButtons";

const MyEvaluationMultipleChoice = () => {
  return (
    <Box width="100%">
      <MyEvaluationQuestion />
      <MyEvaluationChoices />
      <MyEvaluationNavigationButtons />
    </Box>
  );
};

export default MyEvaluationMultipleChoice;
