import { Flex, useDisclosure, useToast } from "@chakra-ui/react";
import { useCallback, useContext, useState, useEffect } from "react";
import { EvaluationActions, EvaluationContext } from "./MyEvaluationContext";
import "moment/locale/pt-br";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import MyEvaluationTable from "./MyEvaluationTable";
import MyEvaluationDrawer from "./MyEvaluationDrawer";
import { post } from "utils/fetch";
import { useNavigationCorrection } from "hooks/useNavigationCorrection";

const MyEvaluationMarkers = () => {
  const { tags, dispatch, setRecommendedTags, maxScore } =
    useContext(EvaluationContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedTag, setSelectedTag] = useState();
  const [filteredTags, setFilteredTags] = useState([]);
  const [filters, setFilters] = useState([]);
  const { t } = useTranslation();
  const { responseId, questionId } = useNavigationCorrection();
  const toast = useToast();

  const onDeleteTag = async (tag) => {
    if (tag.isRecommendation) {
      post("api/courses/correction/ai_tags", {
        question_id: questionId,
        answer_id: responseId,
        tag_id: tag.id,
        accepted: false,
      });
      setRecommendedTags((state) => state.filter((r) => tag.id !== r.id));
    } else {
      await post(
        "api/courses/correction/ai_tags",
        { tag_id: tag.id, answer_id: responseId },
        "DELETE"
      )
        .then(() => {
          toast({
            description: t("FEEDBACKS.DELETE_TAG_SUCCESS"),
            duration: 3000,
            status: "success",
            isClosable: true,
            position: "top",
          });
        })
        .catch(() => {
          toast({
            description: t("FEEDBACKS.DELETE_TAG_ERROR"),
            duration: 3000,
            status: "error",
            isClosable: true,
            position: "top",
          });
        });
      dispatch({ type: EvaluationActions.removeRange, payload: tag });
      if (tags.length === 1 && maxScore === null) {
        dispatch({
          type: EvaluationActions.updateEvaluation,
          payload: { score: null },
        });
      }
    }
  };

  const onClickRemove = useCallback(() => {
    onDeleteTag(selectedTag);
    onClose();
  }, [selectedTag, dispatch]);

  useEffect(() => {
    if (filters.length) {
      setFilteredTags(
        tags
          .filter((item) => !item.isRecommendation)
          .filter((item) => filters.includes(item.type))
      );
    } else {
      setFilteredTags(tags.filter((item) => !item.isRecommendation));
    }
  }, [tags]);

  function filterTagsByType(filtersList) {
    if (filtersList.length) {
      setFilters(filtersList);
      setFilteredTags(
        tags
          .filter((item) => !item.isRecommendation)
          .filter((item) => filters.includes(item.type))
      );
    } else {
      setFilters(filtersList);
      setFilteredTags(tags.filter((item) => !item.isRecommendation));
    }
  }

  const onEditTag = (tag) => {
    setSelectedTag(tag);
    onOpen();
  };

  return (
    <Flex justifyContent="flex-start" width="100%" flexDirection="column">
      <MyEvaluationDrawer
        onDismiss={onClose}
        visible={isOpen}
        range={selectedTag}
        onClickDelete={onClickRemove}
        onClickSave={onClose}
      />
      <MyEvaluationTable
        title={t("QUESTION_MARKERS")}
        tags={filteredTags}
        filters={filters}
        filterTagsByType={filterTagsByType}
        onDelete={onDeleteTag}
        onEdit={onEditTag}
        titleWarning={t("QUESTION_MARKERS_WARNING_TITLE")}
        descWarning={t("QUESTION_MARKERS_WARNING_DESC")}
        titleHelpPopover={t("HOW_ADD_TAGS?.TITLE")}
        descriptionHelpPopover={t("HOW_ADD_TAGS?.DESCRIPTION")}
      />
    </Flex>
  );
};

export default MyEvaluationMarkers;
