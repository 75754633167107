import { QuestionOutlineIcon } from "@chakra-ui/icons";
import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

function HelperPopover({ title, description }) {
  const { t } = useTranslation();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const initialFocusRef = useRef();
  return (
    <Popover
      initialFocusRef={initialFocusRef}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      placement="auto"
      closeOnBlur
    >
      <PopoverTrigger>
        <QuestionOutlineIcon color="blue.600" cursor="pointer" marginLeft={2} />
      </PopoverTrigger>
      <PopoverContent p={2}>
        <PopoverHeader pt={4} fontWeight="bold" border="0">
          {title}
        </PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <Text>{description}</Text>
        </PopoverBody>
        <PopoverFooter
          border="0"
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          pb={4}
        >
          <Button colorScheme="blue" size="sm" onClick={onClose}>
            {t("UNDERSTAND")}
          </Button>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}

export default HelperPopover;
