import { createContext, useContext, useEffect, useState } from "react";

const BlindCorrectionContext = createContext({});

export const BlindCorrectionProvider = ({ children }) => {
  const [isBlind, setIsBlind] = useState(
    sessionStorage.getItem("blindCorrection") || false
  );

  useEffect(() => {
    sessionStorage.setItem("blindCorrection", isBlind);
  }, [isBlind]);

  return (
    <BlindCorrectionContext.Provider value={{ isBlind, setIsBlind }}>
      {children}
    </BlindCorrectionContext.Provider>
  );
};

export const useBlindCorrection = () => {
  const context = useContext(BlindCorrectionContext);

  if (!context)
    throw new Error(
      '"useBlindCorrection" must be used inside a BlindCorrectionProvider.'
    );

  const { isBlind, setIsBlind } = context;

  return { isBlind, setIsBlind };
};
