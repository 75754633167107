import { useEffect, useState } from "react";
import { Box, Flex, Spinner, Text, Textarea } from "@chakra-ui/react";
import {
  getGeneralComments,
  postGeneralComments,
} from "service/MyEvaluationServices";
import { useTranslation } from "react-i18next";
import useDebounce from "utils/useDebounce";
import HelperPopover from "components/HelperPopover";

const GeneralCommentEditor = ({
  comment = "",
  onChangeTextAreaHandler = () => {},
  isSaveButtonLoading = false,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Textarea
        mt="2"
        maxHeight={200}
        value={comment}
        onChange={onChangeTextAreaHandler}
        size="sm"
      />
      {isSaveButtonLoading && (
        <Flex alignItems={"center"}>
          <Spinner color="gray.400" boxSize={2} mr={2} />
          <Text fontSize="sm" textColor="gray.400">
            {t("SAVING")}...
          </Text>
        </Flex>
      )}
    </>
  );
};

const MyEvaluationGeneralComment = ({ responseID = "", questionID = "" }) => {
  const [comment, setComment] = useState(null);
  const [isSaveButtonLoading, setIsSaveButtonLoading] = useState(false);
  const { t } = useTranslation();
  const debouncedComment = useDebounce(comment, 2000);

  useEffect(() => {
    getGeneralComments(responseID, questionID, generalCommentCallback);
  }, []);

  const onClickSaveCommentHandler = async (comment) => {
    postGeneralComments(
      comment,
      responseID,
      questionID,
      generalCommentCallback
    );
  };

  useEffect(() => {
    if (debouncedComment !== null) {
      onClickSaveCommentHandler(debouncedComment);
    }
  }, [debouncedComment]);

  const onChangeTextAreaHandler = (event) => {
    setIsSaveButtonLoading(true);
    setComment(event.target.value);
  };

  const generalCommentCallback = (comment) => {
    setIsSaveButtonLoading(false);
    setComment(comment);
  };

  return (
    <Box>
      <Flex alignItems="center">
        <Text className="title" mb={0}>
          {t("GENERAL_COMMENTS")}
        </Text>
        <HelperPopover
          title={t("WHAT_IS_GENERAL_COMMENTS?.TITLE")}
          description={t("WHAT_IS_GENERAL_COMMENTS?.DESCRIPTION")}
        />
      </Flex>
      <GeneralCommentEditor
        comment={comment}
        onChangeTextAreaHandler={onChangeTextAreaHandler}
        onClickSaveCommentHandler={onClickSaveCommentHandler}
        isSaveButtonLoading={isSaveButtonLoading}
      />
    </Box>
  );
};

export default MyEvaluationGeneralComment;
