import { IconButton } from "@chakra-ui/react";
import { useBlindCorrection } from "hooks/useBlindCorrection";
import { FaEye, FaEyeSlash } from "react-icons/fa6";

export default function BlindCorrectionButton() {
  const { isBlind, setIsBlind } = useBlindCorrection();

  return (
    <IconButton
      style={{ position: "fixed", bottom: 30, right: 30, zIndex: 999 }}
      isRound={true}
      variant="solid"
      colorScheme="blue"
      aria-label="Done"
      fontSize="30px"
      size="lg"
      icon={isBlind ? <FaEyeSlash /> : <FaEye />}
      onClick={() => setIsBlind(!isBlind)}
    />
  );
}
