export const validateEmail = (email) => {
  return !/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i.test(email);
};

export const validatePasswordPolicy = (password, t) => {
  const minLength = 8;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[!@#$%^&*]/.test(password);

  if (!password || password.length < minLength) {
    return t("PASSWORD_POLICY.LENGTH");
  } else if (!hasUpperCase) {
    return t("PASSWORD_POLICY.UPPERCASE");
  } else if (!hasLowerCase) {
    return t("PASSWORD_POLICY.LOWERCASE");
  } else if (!hasNumber) {
    return t("PASSWORD_POLICY.NUMBER");
  } else if (!hasSpecialChar) {
    return t("PASSWORD_POLICY.SPECIAL_CHAR");
  }
  return "";
};
