import React, { useState, useEffect, useMemo } from "react";

import {
  Box,
  Divider,
  Flex,
  Heading,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  useDisclosure,
  AlertDialogFooter,
  Avatar,
  Text,
  IconButton,
  Image,
  Grid,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  useToast,
  Skeleton,
  useBreakpointValue,
} from "@chakra-ui/react";

import {
  getThresholdIndex,
  postThresholdIndex,
  getPlagiumIndex,
  postPlagiumIndex,
  getProfile,
  postProfile,
  deleteAccount,
  putFeedbackButton,
} from "service/SettingsViewServices";

import { fetchUserData } from "utils/user";
import ContainerLayout from "../components/ContainerLayout";
import { EditIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import BgSettings from "../media/BANNER.png";
import ChangePasswordModal from "components/SettingsView/ChangePasswordModal";
import AlgorithmItem from "components/SettingsView/AlgorithmItem";
import { useQuery } from "utils/useQuery";

const CardContainer = ({ width, children, marginLeft }) => {
  return (
    <Flex
      border="1px solid #e2e8f0"
      borderRadius={5}
      w={width}
      p={6}
      ml={marginLeft}
      flexDirection="column"
    >
      {children}
    </Flex>
  );
};

const SettingsView = () => {
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const [username, setUsername] = useState("");
  const [institution, setInstitution] = useState("");
  const [plagiumIndex, setPlagiumIndex] = useState("");
  const [thresholdIndex, setThresholdIndex] = useState("");
  const [feedback, setFeedback] = useState(null);
  const toast = useToast();
  const breakpoint = useBreakpointValue({ base: "mobile", lg: "web" });
  const query = useQuery();
  const hasParams = useMemo(() => {
    return query.get("token") && query.get("action");
  }, [query]);

  const {
    isOpen: deleteAccountIsOpen,
    onOpen: deleteAccountOnOpen,
    onClose: deleteAccountOnClose,
  } = useDisclosure();

  const cancelRef = React.useRef();

  const { t } = useTranslation();

  const saveEditFormHandler = async () => {
    try {
      await postProfile(username, institution);
      setIsFormDisabled(true);
      toast({
        title: t("FEEDBACKS.CHANGES_SAVED"),
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      toast({
        title: t("FEEDBACKS.ERROR"),
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const plagiumIndexHandler = (newplagiumIndex) => {
    postPlagiumIndex(newplagiumIndex, setPlagiumIndex);
  };

  const thresholdIndexHandler = (newthresholdIndex) => {
    postThresholdIndex(newthresholdIndex, setThresholdIndex);
  };

  const feedbackButtonHandler = (value) => {
    putFeedbackButton(value, setFeedback);
  };

  const getFeedback = async () => {
    const res = await fetchUserData();
    setFeedback(res.ai_feedback);
  };

  useEffect(() => {
    getFeedback();
    getThresholdIndex(setThresholdIndex);
    getPlagiumIndex(setPlagiumIndex);
    getProfile(setUsername, setInstitution);
  }, []);

  const algorithmSensitivity = useMemo(() => {
    return [
      {
        itemName: t("BOOKMARK_SUGGESTION"),
        itemValue: thresholdIndex,
        itemCallback: (val) => {
          thresholdIndexHandler(val);
        },
        showSelect: true,
      },
      {
        itemName: t("PLAGIARISM_MECHANISM"),
        itemValue: plagiumIndex,
        itemCallback: (val) => {
          plagiumIndexHandler(val);
        },
        showSelect: true,
      },
      {
        itemName: t("FEEDBACK_SUGGESTION_CONFIG"),
        itemValue: feedback,
        itemCallback: (val) => {
          feedbackButtonHandler(val);
        },
        showSelect: false,
      },
    ];
  }, [plagiumIndex, thresholdIndex, feedback, t]);

  const deleteAccountUser = async () => {
    try {
      await deleteAccount();
      deleteAccountOnClose();
      window.localStorage.clear();
      window.location.replace("/");
    } catch (error) {
      toast({
        title: t("FEEDBACKS.ERROR"),
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const isLoadingSettings = plagiumIndex && feedback !== null;

  return (
    <ContainerLayout>
      <Box width="100%" paddingLeft="40px">
        <Heading width="100%" size="lg" textAlign="left">
          {t("SETTINGS")}
        </Heading>

        <Divider my={6} />
        <Flex flexDirection={breakpoint === "web" ? "row" : "column"} gap={4}>
          <CardContainer width={breakpoint === "web" ? "60%" : "100%"}>
            <Skeleton isLoaded={isLoadingSettings}>
              <Image
                src={BgSettings}
                alt="Background settings"
                width="100%"
                maxHeight="150px"
                minHeight="120px"
                objectFit="cover"
              />

              <Flex>
                <Avatar
                  size="2xl"
                  name={
                    JSON.parse(window.localStorage.getItem("currentUser"))?.name
                  }
                  bgColor="#A4A4EB"
                  color="whitesmoke"
                  marginTop="-70px"
                  marginLeft="20px"
                  marginRight="20px"
                  border="5px solid white"
                />
                <Flex flexDirection="column" mt="-20px">
                  <Text fontSize={20} fontWeight="medium">
                    {
                      JSON.parse(window.localStorage.getItem("currentUser"))
                        ?.name
                    }{" "}
                    {!hasParams && (
                      <IconButton
                        onClick={() => setIsFormDisabled(false)}
                        icon={<EditIcon />}
                        variant="ghost"
                        colorScheme="blue"
                        isRound={true}
                      />
                    )}
                  </Text>
                  <Text>
                    {
                      JSON.parse(window.localStorage.getItem("currentUser"))
                        ?.email
                    }
                  </Text>
                </Flex>
              </Flex>
              <Grid templateColumns="repeat(2, 1fr)" gap={4} p={4}>
                <FormControl isReadOnly={isFormDisabled}>
                  <FormLabel htmlFor="name">{t("NAME")}</FormLabel>
                  <Input
                    id="name"
                    value={username}
                    onChange={(e) => setUsername(e.currentTarget.value)}
                  />
                </FormControl>
                <FormControl isReadOnly={true}>
                  <FormLabel htmlFor="email">{t("EMAIL")}</FormLabel>
                  <Input
                    id="email"
                    type="email"
                    value={
                      JSON.parse(window.localStorage.getItem("currentUser"))
                        ?.email
                    }
                  />
                </FormControl>
                <FormControl isReadOnly={isFormDisabled}>
                  <FormLabel htmlFor="institution">
                    {t("INSTITUTION")}
                  </FormLabel>
                  <Input
                    id="institution"
                    value={institution}
                    onChange={(e) => setInstitution(e.currentTarget.value)}
                  />
                </FormControl>
                <FormControl isReadOnly={true}>
                  <FormLabel htmlFor="password">{t("PASSWORD")}</FormLabel>
                  <InputGroup size="md">
                    <Input pr="4.5rem" type="password" value="*********" />
                    {!hasParams && (
                      <InputRightElement width="3rem">
                        <ChangePasswordModal />
                      </InputRightElement>
                    )}
                  </InputGroup>
                </FormControl>
              </Grid>
              <Flex p="4" justify="space-between">
                {!hasParams && (
                  <Button colorScheme="red" onClick={deleteAccountOnOpen}>
                    {t("DELETE_ACCOUNT")}
                  </Button>
                )}
                {!isFormDisabled && (
                  <Flex gap={4}>
                    <Button
                      colorScheme="gray"
                      onClick={() => setIsFormDisabled(true)}
                    >
                      {t("CANCEL")}
                    </Button>
                    <Button colorScheme="blue" onClick={saveEditFormHandler}>
                      {t("SAVE")}
                    </Button>
                  </Flex>
                )}
                <AlertDialog
                  isOpen={deleteAccountIsOpen}
                  leastDestructiveRef={cancelRef}
                  onClose={deleteAccountOnClose}
                >
                  <AlertDialogOverlay>
                    <AlertDialogContent>
                      <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        {t("DELETE_ACCOUNT")}
                      </AlertDialogHeader>
                      <AlertDialogBody>{t("DELETE_ACCOUNT?")}</AlertDialogBody>
                      <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={deleteAccountOnClose}>
                          {t("CANCEL")}
                        </Button>
                        <Button
                          colorScheme="red"
                          onClick={deleteAccountUser}
                          ml={3}
                        >
                          {t("DELETE")}
                        </Button>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialogOverlay>
                </AlertDialog>
              </Flex>
            </Skeleton>
          </CardContainer>
          <CardContainer width={breakpoint === "web" ? "40%" : "100%"}>
            <Skeleton isLoaded={isLoadingSettings}>
              <Text fontSize={24} fontWeight="medium">
                {t("ALGORITHM_SENSITIVITY")}
              </Text>
              <Text mt={4}>{t("ALGORITHM_SENSITIVITY_TOOLTIP")}</Text>

              {algorithmSensitivity?.map((item) => (
                <AlgorithmItem
                  key={item.itemName}
                  itemName={item.itemName}
                  itemValue={item.itemValue}
                  itemCallback={(val) => {
                    item.itemCallback(val);
                  }}
                  showSelect={item.showSelect}
                />
              ))}
            </Skeleton>
          </CardContainer>
        </Flex>
      </Box>
    </ContainerLayout>
  );
};

export default SettingsView;
