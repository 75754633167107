import { get, post } from "utils/fetch";

export const getThresholdIndex = async (callback) => {
  get("/api/profile/ai/")
    .then((data) => {
      callback(data.ai_threshold);
    })
    .catch((err) => console.log("Error: ", err));
};

export const postThresholdIndex = async (value, callback) => {
  post("/api/profile/ai/", {
    ai_threshold: Number(value),
  })
    .then((data) => {
      callback(data.ai_threshold);
    })
    .catch((err) => {
      callback(value);
      console.log("Error: ", err);
    });
};

export const putFeedbackButton = async (value, callback) => {
  post(
    "/api/profile/feedback/",
    {
      ai_feedback: value,
    },
    "PUT"
  )
    .then((data) => {
      callback(data.ai_feedback);
    })
    .catch((err) => {
      callback(value);
      console.log("Error: ", err);
    });
};

export const getPlagiumIndex = async (callback) => {
  get("/api/profile/plagium/")
    .then((data) => {
      callback(data.plagium_threshold);
    })
    .catch((err) => console.log("Error: ", err));
};

export const postPlagiumIndex = async (value, callback) => {
  post("/api/profile/plagium/", {
    plagium_threshold: value,
  })
    .then((data) => {
      callback(data.plagium_threshold);
    })
    .catch((err) => {
      callback(value);
      console.log("Error: ", err);
    });
};

export const getProfile = async (callbackName, callbackInstitution) => {
  get("/api/profile/")
    .then((data) => {
      callbackName(data.name);
      callbackInstitution(data.university);
      localStorage.setItem("user-lang", data.language);
    })
    .catch((err) => console.log("Error: ", err));
};

export const postProfile = async (name, institution) => {
  post(
    "/api/profile/",
    {
      name: name,
      university: institution,
    },
    "PUT"
  );
};

export const postLang = async (lang) => {
  post(
    "/api/profile/",
    {
      language: lang,
    },
    "PUT"
  );
};

export const deleteAccount = async () => {
  get("/api/auth/me/", "json", "DELETE");
};

export const changePassword = async (old_password, new_password) => {
  await post("/api/auth/change-password/", {
    old_password,
    new_password,
  });
};
